export const commonStyles = {
    spinner: {
        inButton: {
            color: "silver",
            margin: "unset",
            position: "absolute",
            right: "1em",
        }
    }
}
