import {CognitoUser, CognitoUserAttribute} from "amazon-cognito-identity-js"
import {API, Auth} from "./amplify-wrapper"
import {gardenApi, StripeData} from "../config"
import {loadStripe} from "@stripe/stripe-js/pure"

export interface CheckoutPrice {
    id: string
    isTrial?: boolean
}

const StripeAttributes = {
    id: "custom:StripeId",
    subscriptionEnd: "custom:StripeSubEnd",
}

const getUserAttributes = (user: CognitoUser) =>
    new Promise<CognitoUserAttribute[] | undefined>(((resolve, reject) => {
        user.getUserAttributes((err, data) => {
            if (err !== null) reject(err)
            else resolve(data)
        })
    }))


export async function getSubscriptionAttribute(user: CognitoUser) {
    // todo unify with backend
    const attrs = await getUserAttributes(user)

    return attrs?.find(it => it.getName() === StripeAttributes.subscriptionEnd)
}

const hasValidSubscription = async (user: CognitoUser) => {
    const endDate = await getSubscriptionAttribute(user)
    if (!endDate) return false

    return new Date(endDate.getValue()) > new Date()
}

const hasExpiredSubscription = async (user: CognitoUser) => {
    const endDate = await getSubscriptionAttribute(user)
    if (!endDate) return false

    return new Date(endDate.getValue()) < new Date()
}

export const currentUserHasValidSubscription = async () => {
    const user = await Auth.currentAuthenticatedUser()
    return hasValidSubscription(user)
}

export const currentUserHasExpiredSubscription = async () => {
    const user = await Auth.currentAuthenticatedUser()
    return hasExpiredSubscription(user)
}

const createCheckoutSession = async ({id, isTrial = false}: CheckoutPrice) =>
    await API.post(gardenApi, "/billing/create-checkout-session", {body: {priceId: id, isTrial}})

export async function redirectToCheckout(checkoutPrice: CheckoutPrice) {
    const stripe = await loadStripe(StripeData.publishableKey)

    const checkoutSession = await createCheckoutSession(checkoutPrice)

    await stripe!!.redirectToCheckout({
        sessionId: checkoutSession.sessionId,
    })
    //todo handle error by showing message
}

