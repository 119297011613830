import React, {useEffect, useState} from 'react'

import {Box, Button, Container, Flex, Heading, Spinner} from "theme-ui"
import SectionHeading from '../components/section-heading'
import Layout from '../components/layout'
import {API, Auth} from '../common/amplify-wrapper'
import {Link} from "gatsby"
import {CognitoUser} from 'amazon-cognito-identity-js'
import {domain, gardenApi} from "../config"
import {navigate} from "@reach/router"
import {SignOut, withAuth} from "@roam-garden/landscaping-toolbox"

import {commonStyles} from "../common/styles"
import {getSubscriptionAttribute} from "../common/billing"


const customerPortal = async () =>
    await API.post(gardenApi, "/billing/customer-portal", {})

async function manageBilling() {
    const portal = await customerPortal()
    await navigate(portal.url)
}


function ManageBillingButton() {
    const [isLoading, setIsLoading] = useState(false)

    return <Button
        sx={{
            marginRight: "5em",
            position: "relative",
        }}
        onClick={async () => {
            setIsLoading(true)
            await manageBilling()
        }}
    >
        Manage Subscription
        {isLoading && <Spinner sx={{
            ...commonStyles.spinner.inButton,
            right: "4em",
            top: "0",
        }}/>}
    </Button>
}

const User = () => {
    const [url, setUrl] = useState("")
    const [hasSubscriptionAttr, setHasSubscriptionAttr] = useState(true)

    useEffect(() => {
        (async () => {
            const user: CognitoUser = await Auth.currentAuthenticatedUser()
            setUrl(`https://${user.getUsername()}.${domain}/`)
            setHasSubscriptionAttr(await getSubscriptionAttribute(user))
        })()
    }, [])

    return (
        <Layout>
            <Box as="section" id="success" sx={styles.section}>
                <Container>
                    <SectionHeading title="Profile"/>
                    <Flex sx={{
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                        <Heading as="h3">Garden Address</Heading>
                        <Link to={url}>{url}</Link>

                        <Flex sx={styles.row}>
                            {hasSubscriptionAttr && <ManageBillingButton/>}
                            <SignOut onSignOut={() => navigate("/")}/>
                        </Flex>
                    </Flex>

                </Container>

            </Box>
        </Layout>)
}

export default withAuth(User)

const styles = {
    section: {
        pt: "140px",
        pb: [12, null, null, null, null, 15],
        maxWidth: "50vw",
        margin: "auto",
    },
    row: {
        marginTop: "1em",
    },
}
